<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>

    <div class="big-vid-wrap top">
      <div class="big-vid">
        <iframe
          src="https://vimeo.com/showcase/10377174/embed"
          allowfullscreen
          frameborder="0"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        ></iframe>
      </div>
    </div>
    <div class="center">
      <p class="txt20 light">
        Contact your Play.Works business representative for access.
      </p>
      <p class="txt20">For business inquiries contact us at</p>
      <div class="cont-links-wrap">
        <a
          href="mailto:contact@play.works"
          target="_blank"
          class="cont-small-link"
          >contact@play.works</a
        >
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  name: "Partners",
  components: {
    Topnav,
    Botfooter,
  },
};
</script>
